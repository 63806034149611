import { computed, Injectable, signal } from '@angular/core';
import {
	addDoc,
	collection,
	collectionGroup,
	CollectionReference,
	collectionSnapshots,
	deleteDoc,
	doc,
	DocumentData,
	DocumentReference,
	Firestore,
	FirestoreError,
	getDoc,
	getDocs,
	limit,
	orderBy,
	Query,
	query,
	setDoc,
	updateDoc,
	where,
} from '@angular/fire/firestore';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GoogleIdentityService, MAS_Programs_Service } from '.';
import { MasAccountConverterService } from '../converters/mas-accounts.converter';
import { Account, AccountAttendance, AttendanceViolation, MASAccountModel, TournamentParticipation, TournamentStanding } from '../models';
import { AdvancedDebugging, captureError, EmailMessage, SendEmail } from '../utilities';

@Injectable({
	providedIn: 'root',
})
export class MAS_Accounts_Service {
	private accountInitialized = new BehaviorSubject<boolean>(false);
	private _selectedNode = signal<Account | null>(null);
	private _memberId = signal<string>('');
	private _memberName = signal<string>('');
	private _billingEmail = signal<string | null>(null);
	private _myAccount = signal<Account | null>(null);
	private _linkedAccounts = signal<Account[]>([]);
	private _includeYAS = signal<boolean>(false);
	private _myProgramsList = signal<string[]>([]);
	private advancedDebug = new AdvancedDebugging();

	// Expose signals as readonly properties
	public readonly selectedNode = computed(() => this._selectedNode());
	public readonly memberId = computed(() => this._memberId());
	public readonly memberName = computed(() => this._memberName());
	public readonly billingEmail = computed(() => this._billingEmail());
	public readonly myAccount = computed(() => this._myAccount());
	public readonly linkedAccounts = computed(() => this._linkedAccounts());
	public readonly includeYAS = computed(() => this._includeYAS());
	public readonly myProgramsList = computed(() => this._myProgramsList());

	constructor(public firestore: Firestore, private identityService: GoogleIdentityService, private programsService: MAS_Programs_Service, private masAccountConverter: MasAccountConverterService) {}

	public get firestoreInstance(): Firestore {
		return this.firestore;
	}

	public setSelectedNode(account: Account) {
		this._selectedNode.set(account);
	}

	public setMemberId(id: string) {
		this._memberId.set(id);
	}

	public setBillingEmail(email: string | null) {
		this._billingEmail.set(email);
	}

	public updateLinkedAccounts(accounts: Account[]) {
		this._linkedAccounts.set(accounts);
	}

	public get converter(): MasAccountConverterService {
		return this.masAccountConverter;
	}

	public async fetchAccountData(userId: string) {
		try {
			const docRef = doc(this.firestore, 'accounts', userId);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				this._myAccount.set(docSnap.data() as Account);
			}
		} catch (error) {
			Sentry.captureException(error);
			console.error('fetchAccountData error', error);
		}
	}

	/* 	async checkAccountQuality(account: Account): Promise<{ accepted: boolean; msg: string }> {
		try {
			const errors: string[] = [];

			if (!account.mas.nodeType) errors.push('Account missing nodeType.');

			account.mas.linkedAccounts?.forEach(link => {
				if (!link.accountId) errors.push('Linked account missing accountId.');
			});

			const validStatuses = ['Active', 'Inactive', 'New', 'Pending', 'Trial'];
			if (!validStatuses.includes(account.mas.accountSettings.status)) {
				errors.push('Account status is invalid.');
			}

			if (account.memberships) {
				const uniqueMemberships = new Set(account.memberships);
				if (uniqueMemberships.size !== account.memberships.length) {
					if (window.location.hostname === 'localhost') {
						console.log('Duplicate membership found. Attempting to resolve.');
					}
					account.memberships = Array.from(uniqueMemberships);
					await this.setAccount(account.id, account, 'existing');
				}
			}

			if (errors.length === 0) {
				return { accepted: true, msg: 'No errors' };
			} else {
				throw new Error(errors.join(' '));
			}
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error in checkAccountQuality:', error);
			throw error;
		}
	}
 */
	async createAccount(payload: Account): Promise<DocumentReference<DocumentData>> {
		try {
			const ref = collection(this.firestore, 'mas-accounts');
			return await addDoc(ref, payload);
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error creating account:', error);
			throw error;
		}
	}

	async deleteAccountAttendance(memberId: string, documentId: string): Promise<void> {
		try {
			const ref = doc(this.firestore, `mas-accounts/${memberId}/mas-accounts-attendance`, documentId);
			await deleteDoc(ref);
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error deleting account attendance:', error);
			throw error;
		}
	}

	async getAccounts(): Promise<Account[]> {
		try {
			const q = query(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>);
			const snapshot = await getDocs(q);
			return snapshot.docs.map(m => ({ ...m.data(), id: m.id }));
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error fetching accounts:', error);
			throw error;
		}
	}

	async getAccountsAsModels(): Promise<MASAccountModel[]> {
		try {
			const q = query(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>);
			const snapshot = await getDocs(q);

			return snapshot.docs.map(doc =>
				this.masAccountConverter.fromRaw({
					...doc.data(),
					id: doc.id,
				})
			);
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error fetching accounts:', error);
			throw error;
		}
	}

	async archiveCollection(path: string): Promise<void> {
		try {
			const q = query(collection(this.firestore, path) as CollectionReference<any>);
			const snapshot = await getDocs(q);

			const archivePromises = snapshot.docs.map(async docSnapshot => {
				const ref = doc(this.firestore, `${path}-archive`, docSnapshot.id);
				await setDoc(ref, docSnapshot.data());
			});

			await Promise.all(archivePromises);
			console.log(`Successfully archived collection at path: ${path}`);
		} catch (error) {
			Sentry.captureException(error);
			console.error(`Error archiving collection at path: ${path}`, error);
			throw new Error(`Failed to archive collection at path: ${path}`);
		}
	}

	async getAccountAttendanceById(accountId: string, scheduleId: string): Promise<Boolean> {
		const docRef = doc(this.firestore, `mas-accounts/${accountId}/mas-accounts-attendance/`, scheduleId);
		const docSnap = await getDoc(docRef);
		return docSnap.exists();
	}

	async getAccountAttendance(id: string, programs: string[]): Promise<AccountAttendance[]> {
		// Early return if programs array is empty
		if (!programs || programs.length === 0) return [];
		const q = query<AccountAttendance, DocumentData>(
			collection(this.firestore, `mas-accounts/${id}/mas-accounts-attendance/`) as CollectionReference<AccountAttendance>,
			where('scheduleName', 'in', programs)
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountViolations(id: string, cycleName?: string): Promise<AttendanceViolation[]> {
		let q: Query<AttendanceViolation>;

		if (cycleName) {
			q = query<AttendanceViolation, DocumentData>(
				collection(this.firestore, `mas-accounts/${id}/mas-accounts-violation/`) as CollectionReference<AttendanceViolation>,
				where('cycleName', '==', cycleName)
			);
		} else {
			q = query<AttendanceViolation, DocumentData>(collection(this.firestore, `mas-accounts/${id}/mas-accounts-violation/`) as CollectionReference<AttendanceViolation>);
		}

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => {
			const data = m.data();
			data.id = m.id;
			return data;
		});
	}

	async getAccountAttendanceCount(id: string, programs: string[], cycle: string) {
		// Early return if programs array is empty
		if (!programs || programs.length === 0) return [];
		const q = query<AccountAttendance, DocumentData>(
			collection(this.firestore, `mas-accounts/${id}/mas-accounts-attendance/`) as CollectionReference<AccountAttendance>,
			where('scheduleName', 'in', programs),
			where('cycleName', '==', cycle)
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountTournaments(id: string) {
		const q = query<TournamentParticipation, DocumentData>(collection(this.firestore, `mas-accounts/${id}/mas-accounts-tournaments/`) as CollectionReference<TournamentParticipation>);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountStandings(id: string) {
		const q = query<TournamentStanding, DocumentData>(collection(this.firestore, `mas-accounts/${id}/mas-accounts-standings/`) as CollectionReference<TournamentStanding>);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountAttendanceByProgram(accountId: string, program: string): Promise<number> {
		const q = query(collection(this.firestore, `mas-accounts/${accountId}/mas-accounts-attendance/`), where('scheduleName', '==', program));
		return getDocs(q).then(data => {
			return data.size;
		});
	}

	async getAccountsByEmail(email: string): Promise<boolean> {
		try {
			// Fetch the parent account using the email
			const q = query<Account, DocumentData>(
				collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
				where('emailAddresses.value', '==', email),
				where('mas.nodeType', '==', 'parent')
			);

			const qDocs = await getDocs(q);
			const account = qDocs.docs.pop()?.data() as Account;

			if (window.location.hostname == 'localhost') console.log('getAccountsByEmail=>myAccount', account);

			// If no parent account is found, log out and return false
			if (!account) {
				console.log('Invalid MAS Email:', email);
				this.identityService.signOut();
				window.alert('Invalid MAS Email!');
				return false;
			}

			// Set `myAccount` using Signals
			this._myAccount.set(account);

			// Fetch linked child accounts
			const linkedAccountIds = account.mas.linkedAccounts?.map(m => m.accountId) ?? [];
			const linkedAccounts = linkedAccountIds.length ? await this.getAccountsById(linkedAccountIds) : [];
			if (window.location.hostname == 'localhost') console.log('getAccountsByEmail=>linkedAccounts', linkedAccounts);

			// Set `linkedAccounts` using Signals
			this._linkedAccounts.set(linkedAccounts);

			// Merge memberships from parent and linked child accounts
			const allMemberships = new Set<string>();

			// Add memberships from linked child accounts
			linkedAccounts.forEach(child => {
				child.memberships?.forEach(membership => allMemberships.add(membership));
			});

			// Add memberships from parent account if they also take classes
			if (account.mas?.accountSettings?.member === 'true') {
				account.memberships?.forEach(membership => allMemberships.add(membership));
			}

			// Set `myProgramsList` with the merged memberships
			const myProgramsListArray = Array.from(allMemberships);
			this._myProgramsList.set(myProgramsListArray);

			if (window.location.hostname == 'localhost') console.log('getAccountsByEmail=>myProgramsList', myProgramsListArray);

			// Fetch YAS program members
			const YAS = await this.programsService.getProgramByName('YAS');
			const yasMembership = YAS?.memberships.map(m => m.memberId) ?? [];
			if (window.location.hostname == 'localhost') console.log('getAccountsByEmail=>yasMembership', yasMembership);

			// Check if any of the user’s accounts (parent or children) are in YAS
			const accountIds = [...linkedAccountIds, account.id];
			const includeYAS = accountIds.some(id => yasMembership.includes(id));
			if (window.location.hostname == 'localhost') console.log('getAccountsByEmail=>includeYAS', includeYAS);

			// Set `includeYAS` using Signals
			this._includeYAS.set(includeYAS);

			return true;
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error fetching accounts by email:', error);
			throw error;
		}
	}

	async waitForAccountInitialization(): Promise<void> {
		// Wait until myAccount is set
		while (!this.myAccount()) {
			await new Promise(resolve => setTimeout(resolve, 50)); // Small delay to prevent CPU blocking
		}
	}

	async getAccountByEmail(email: string): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('emailAddresses.value', '==', email),
			where('mas.accountSettings.billing', '==', 'true'),
			limit(1)
		);

		const snapshot = await getDocs(q);
		const accounts = snapshot.docs.map(m => m.data() as Account);

		if (accounts.length > 0) {
			this._myAccount.set(accounts[0]); // Use the Signal setter
			if (window.location.hostname == 'localhost') console.log('getAccountByEmail=>myAccount', accounts[0]);
		}

		return accounts;
	}

	async getAccountById(id: string): Promise<Account> {
		const docRef = doc(this.firestore, 'mas-accounts', id);
		const docSnap = await getDoc(docRef);

		if (!docSnap.exists()) {
			throw new Error(`Account not found for ID: ${id}`);
		}

		return docSnap.data() as Account;
	}

	async getAccountsByLinked(links: string[]) {
		if (!links || links.length === 0) return [];
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('id', 'in', links));

		try {
			const qDocs = await getDocs(q);

			if (!qDocs) return [];

			return qDocs.docs.map(m => m.data());
		} catch (error) {
			console.error('getAccountsByLinked=>error', error);
			captureError('getAccountsByLinked=>error', error);
			return []; // Or throw error if you want the caller to handle it
		}
	}

	getAccountTrivaData(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(
				collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
				where('mas.triviaQuestionData.cumulativeCorrect', '>', 0),
				where('mas.accountSettings.status', 'in', ['Active', 'Pending', 'Trial']),
				orderBy('mas.triviaQuestionData.cumulativeCorrect')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getAccountsByActiveBillingStatus(): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.status', '!=', 'Inactive'),
			where('mas.accountSettings.billing', '==', 'true'),
			orderBy('names.givenName')
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsByBillingStatus(billing: 'true' | 'false' = 'true') {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.billing', '==', billing),
			orderBy('names.givenName')
		);

		const qDocs = await getDocs(q);
		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountsByActiveMember(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(
				collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
				where('mas.accountSettings.status', '!=', 'Inactive'),
				where('mas.accountSettings.member', '==', 'true'),
				orderBy('names.givenName')
			)
		).pipe(
			map(accountChanges => {
				return accountChanges.map(accounts => {
					const account = accounts.data() as Account;
					account.id = accounts.id;
					return account;
				});
			})
		);
	}

	async getAccountsByMemberAsPromise(): Promise<Account[]> {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.member', '==', 'true'));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountsByActiveParent(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(
				collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
				where('mas.accountSettings.status', '!=', 'Inactive'),
				where('mas.nodeType', '==', 'parent')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	//! WILL BE DEPRECATED - USE getAccountModelsByActiveStatus() INSTEAD
	getAccountsByActiveStatus(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '!=', 'Inactive'))
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}
	getAccountModelsByActiveStatus(): Observable<MASAccountModel[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '!=', 'Inactive'))
		).pipe(
			map(changes =>
				changes.map(snapshot => {
					const raw: Account = { ...snapshot.data(), id: snapshot.id };
					return this.masAccountConverter.fromRaw(raw);
				})
			)
		);
	}

	async getAccountsById(idList: string[]): Promise<Account[]> {
		if (idList.length === 0) return [];
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('id', 'in', idList));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountsByInactiveStatus(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '==', 'Inactive'))
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getAccountsByLinkedProperties(searchProperties: any): Promise<Account[]> {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.linkedAccounts', 'array-contains', searchProperties));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsByProgram(programId: string): Promise<Account[]> {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('memberships', 'array-contains', programId));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsBySchool(school: string): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.school', '==', school),
			where('mas.accountSettings.status', '!=', 'Inactive'),
			where('mas.accountSettings.member', '==', 'true'),
			orderBy('names.givenName')
		);

		const qDocs = await getDocs(q);
		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsWithSchools(): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.school', '>', ''),
			where('mas.accountSettings.status', '!=', 'Inactive'),
			where('mas.accountSettings.member', '==', 'true'),
			orderBy('mas.accountSettings.school')
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getActiveAccountForInspect(): Promise<MASAccountModel[]> {
		try {
			const accountsCollection = collection(this.firestore, 'mas-accounts') as CollectionReference<Account>;
			const accountsQuery = query<Account, DocumentData>(accountsCollection, where('mas.accountSettings.status', '!=', 'Inactive'));
			const querySnapshot = await getDocs(accountsQuery);

			return querySnapshot.docs.map(doc =>
				this.masAccountConverter.fromRaw({
					...doc.data(),
					id: doc.id,
				})
			);
		} catch (error) {
			const firestoreError = error as FirestoreError;
			this.advancedDebug.captureError('UtilitiesService=>getActiveAccountsToConvert', error);
			throw new Error('Failed to fetch accounts: ' + firestoreError.message);
		}
	}

	async getBillingEmail(id: string): Promise<any> {
		const docRef = doc(this.firestore, 'mas-accounts', id);
		const docSnap = await getDoc(docRef);

		const billing = docSnap.data() as Account;
		if (billing.emailAddresses?.value) {
			return billing.emailAddresses?.value;
		} else {
			return null;
		}
	}

	async getReservedEmails(childArray: string[]): Promise<any> {
		const childBatches = [];
		const childDocs: Account[] = [];
		const childDocsSnaps: DocumentData[] = [];

		const parentArray: string[] = [];
		const parentBatches = [];
		const parentDocsSnaps: DocumentData[] = [];
		const parentDocs: Account[] = [];

		const emailArray: string[] = [];

		while (childArray.length) {
			const batch = childArray.splice(0, 10);
			childBatches.push(query(collection(this.firestore, 'mas-accounts'), where('id', 'in', [...batch])));
		}

		for (let i = 0; i < childBatches.length; i++) {
			childDocsSnaps.push(getDocs(childBatches[i]));

			await getDocs(childBatches[i])
				.then(results => results.docs)
				.then(docs => {
					docs.forEach(doc => {
						const data = doc.data() as Account;
						childDocs.push(data);
					});
				});
		}

		childDocs.forEach(doc => {
			const parent = doc.mas.linkedAccounts
				?.filter(f => f.type === 'parent')
				.map(m => m)
				.pop()?.accountId;

			if (doc.emailAddresses?.value) emailArray.push(doc.emailAddresses.value);
			if (parent) parentArray.push(parent);
		});

		while (parentArray.length) {
			const batch = parentArray.splice(0, 10);
			parentBatches.push(query(collection(this.firestore, 'mas-accounts'), where('id', 'in', [...batch])));
		}

		for (let i = 0; i < parentBatches.length; i++) {
			parentDocsSnaps.push(getDocs(parentBatches[i]));

			await getDocs(parentBatches[i])
				.then(results => results.docs)
				.then(docs => {
					docs.forEach(doc => {
						const data = doc.data() as Account;
						parentDocs.push(data);
					});
				});
		}
		return [].concat(emailArray as [], parentDocs.map(m => m.emailAddresses?.value) as []);
	}

	handleSMSReminders(option: boolean) {
		const account = this._myAccount();
		if (!account) return; // Ensure account exists before modifying

		// Create a new object to maintain Signal immutability
		const updatedAccount = {
			...account,
			mas: {
				...account.mas,
				accountSettings: {
					...account.mas.accountSettings,
					enableSMS: option,
				},
			},
		};

		// Update Signal
		this._myAccount.set(updatedAccount);

		// Update Firestore
		const ref = doc(this.firestore, 'mas-accounts', account.id);
		return setDoc(ref, updatedAccount, { merge: true });
	}

	handleEmailReminders(option: boolean) {
		const account = this._myAccount();
		if (!account) return;

		const updatedAccount = {
			...account,
			mas: {
				...account.mas,
				accountSettings: {
					...account.mas.accountSettings,
					enableEmailReminders: option,
				},
			},
		};

		this._myAccount.set(updatedAccount);

		const ref = doc(this.firestore, 'mas-accounts', account.id);
		return setDoc(ref, updatedAccount, { merge: true });
	}

	async setAccount(id: string, payload: Account, mode: 'existing' | 'other' = 'existing'): Promise<Account> {
		if (window.location.hostname == 'localhost') console.log('setAccount=>payload', payload);
		try {
			const method = mode === 'existing' ? true : false;
			const ref = doc(this.firestore, 'mas-accounts', id);
			await setDoc(ref, payload, { merge: method });
			return payload;
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error setting account:', error);
			throw error;
		}
	}

	async setAccountAttendance(id: string, payload: any): Promise<void> {
		if (window.location.hostname == 'localhost') console.log('setAccountAttendance=>payload', payload);

		const ref = doc(this.firestore, `mas-accounts/${id}/mas-accounts-attendance`, payload.scheduleId);
		return await setDoc(ref, payload, { merge: true });
	}

	async setAccountViolation(id: string, payload: any): Promise<void> {
		if (window.location.hostname == 'localhost') console.log('setAccountViolation=>payload', payload);

		const ref = doc(this.firestore, `mas-accounts/${id}/mas-accounts-violation`, payload.scheduleId);
		return await setDoc(ref, payload, { merge: true });
	}

	async setAccountTrivia(id: string, payload: any): Promise<void> {
		if (window.location.hostname == 'localhost') console.log('setAccountTrivia=>payload', payload);

		const ref = doc(this.firestore, `mas-accounts/${id}/mas-accounts-trivia`, payload.lastDate);
		return await setDoc(ref, payload, { merge: true }).then(doc => doc);
	}

	async updateAccount(id: string, payload: Partial<Account>): Promise<void> {
		try {
			const ref = doc(this.firestore, 'mas-accounts', id);
			await updateDoc(ref, payload);
		} catch (error) {
			Sentry.captureException(error);
			console.error('Error updating account:', error);
			throw error;
		}
	}

	async getAttendanceData(id: string) {
		const q = query(collectionGroup(this.firestore, 'mas-accounts-attendance'), where('scheduleId', '>=', id), limit(500));

		const attendance = (await getDocs(q)).docs.map(m => {
			const data = m.data();
			data['id'] = m.id;

			data['parent'] = m.ref.parent.parent?.id;
			return data;
		});
		return attendance as {
			cycleName: string;
			parent: string;
			scheduleId: string;
			scheduleName: string;
		}[];
	}

	getLastTournament(): Observable<{
		tournamentDate: string;
		tournamentName: string;
	}> {
		// Get real-time snapshots of the 'mas-accounts-tournaments' collection group
		const tournaments$ = collectionSnapshots(collectionGroup(this.firestore, 'mas-accounts-tournaments')).pipe(
			map(snapshot => {
				const tournaments = snapshot.map(m => {
					// Explicitly type the data
					const data = m.data() as { [key: string]: any };
					return {
						tournamentDate: data['tournamentDate'],
						tournamentName: data['tournamentName'],
					};
				});

				// Sort tournaments by date in ascending order
				tournaments.sort((a, b) => new Date(a.tournamentDate).getTime() - new Date(b.tournamentDate).getTime());

				// Return the last tournament or a fallback value
				return tournaments.length > 0 ? tournaments[tournaments.length - 1] : { tournamentDate: 'N/A', tournamentName: 'No Data Available' };
			})
		);

		return tournaments$;
	}
	async deleteCorruptAttendance(path: string, id: string) {
		const ref = doc(this.firestore, path, id);
		return await deleteDoc(ref);
	}

	async deleteAccountViolation(memberId: string, documentId: string): Promise<void> {
		const ref = doc(this.firestore, `mas-accounts/${memberId}/mas-accounts-violation`, documentId);
		return await deleteDoc(ref).then(doc => doc);
	}

	async getBillingAccount(account: Account) {
		try {
			if (account.mas.accountSettings.billing === 'false') {
				const parent = account.mas.linkedAccounts?.filter(f => f.type === 'parent');
				if (!parent) throw new Error('missing billing account');
				return await this.getAccountById(parent[0].accountId);
			} else {
				return account;
			}
		} catch (error) {
			throw error;
		}
	}

	async sendEmailViaFirestore(email: EmailMessage): Promise<void> {
		if (window.location.hostname == 'localhost') return;
		const sendEmail = new SendEmail(this.firestore);
		sendEmail.writeEmailToFirestore(email);
	}
}
