import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { AuthGuard } from '../guards/auth.guard';

const appRoutes: Routes = [
	{ path: '', redirectTo: '/accounts', pathMatch: 'full' },
	{ path: 'sign-in', component: SignInComponent },
	{ path: 'accounts', loadChildren: () => import('../modules/accounts.module').then(m => m.AccountsModule) },
	{ path: 'programs', loadChildren: () => import('../modules/programs.module').then(m => m.ProgramsModule) },
	{ path: 'cycles', loadChildren: () => import('../modules/cycles.module').then(m => m.CyclesModule) },
	{ path: 'trivia', loadChildren: () => import('../modules/trivia.module').then(m => m.TriviaModule) },
	{ path: 'pie', loadChildren: () => import('../modules/pie.module').then(m => m.PieModule) },
	{ path: 'utilities', loadChildren: () => import('../modules/utilities.module').then(m => m.UtilitiesModule) },
	{ path: '**', redirectTo: '/accounts' },
];

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forRoot(appRoutes)],
	providers: [AuthGuard],
})
export class AppRoutingModule {}
