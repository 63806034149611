export const gapiSettings = {
	Cfg: {
		apiKey: 'AIzaSyARe0MGnmPNYs_IsFfmeVj95IaHCV7BEOE',
		client_id: '810415461499-9am5esqsooam52fm034f6gj8a3vve44l.apps.googleusercontent.com',
		discoveryDocs: [
			'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
			'https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest',
			'https://content.googleapis.com/discovery/v1/apis/admin/directory_v1/rest',
		],
		scope:
			'https://www.google.com/m8/feeds/contacts ' +
			'https://www.googleapis.com/auth/admin.directory.group ' +
			'https://www.googleapis.com/auth/calendar ' +
			'https://www.googleapis.com/auth/calendar.events ' +
			'https://www.googleapis.com/auth/contacts ' +
			'https://www.googleapis.com/auth/user.emails.read ' +
			'https://www.googleapis.com/auth/userinfo.email ' +
			'https://www.googleapis.com/auth/userinfo.profile ' +
			'https://www.googleapis.com/auth/admin.directory.resource.calendar ',
	},
	CalendarId: 'c_188atbigvau98g0enunlgfiehtvjq4gaf5nmsprjc4n6spbk@resource.calendar.google.com',
	Maps: 'AIzaSyCf2Xc4S4mK1rKdnilmuk5naO9wVsk8ZW8',
	Sheets: 'AIzaSyCntgp0ELEZ8Uca1ypqpopo6yNRF9tQg20',
	WorkspaceId: 'C01h4jo0a',
};
