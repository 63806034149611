export const quickbooks = {
	client_id: 'AB0021HbJyuMptEE30SN8q3leFAh21SmcqBWUAHf4OqrrJegJG',
	client_secret: 'vCVqvaFJCbWiX39LrS2vdRkhciIP9qGCZxcQ4LNe',
	company: '389565371',
	scope: 'com.intuit.quickbooks.accounting',
	response_type: 'code',
	state: 'production',
	redirect_uri: 'https://us-central1-valiant-splicer-224515.cloudfunctions.net/qb/auth_token',
	emulatorEndpoint: 'http://localhost:5001/valiant-splicer-224515/us-central1',
	auth_url: 'https://appcenter.intuit.com/connect/oauth2',
	token_url: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer',
	end_point: 'quickbooks.api.intuit.com',
};
